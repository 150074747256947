.sc-megamenu {
    position: absolute;
    top: 100%;
    left: 0.75rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity $mainTransition;
    padding-top: 0.5rem;

    @include media-breakpoint-up(xxl) {
        right: calc(100% - 306px);
    }

    @media (min-width: $break-xxl) {
        right: calc(100% - 376px);
    }

    > nav {
        flex: 0 0 auto;
        width: 260px;
        max-height: 100%;
        overflow-y: auto;

        @include media-breakpoint-up(xxl) {
            width: 306px;
        }

        @media (min-width: $break-xxl) {
            width: 376px;
        }
    }

    @include media-breakpoint-up(xl) {
        &.active,
        &.with-slideshow {
            opacity: 1;
            visibility: visible;
        }

        &.with-slideshow {
            transition: unset;
            padding-top: 2rem;
            height: 620px;

            @include media-breakpoint-up(xxl) {
                min-height: 652px;
            }

            @media (min-width: $break-xxl) {
                min-height: 670px;
            }

            > div > nav {
                height: 100%;
            }

            .sc-megamenu-list {
                max-height: 610px;
                transition: max-height $mainTransition;
                overflow: hidden;
                height: 100%;

                @include media-breakpoint-up(xxl) {
                    max-height: 620px;
                }

                @media (min-width: $break-xxl) {
                    max-height: 670px;
                }

                &:hover {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    max-height: 1000px;
                }

                &-item >.sc-megamenu-child {
                    top: 2rem;
                }
            }
        }

        &:not(.with-slideshow) {
            z-index: 11000;
        }
    }

    &.active .sc-megamenu-list {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        max-height: 1000px;
    }

    &-list {
        background: #FFFFFF;
        border: $mainBorder;
        border-radius: 4px;
        overflow: hidden;

        &:hover {
            overflow: visible;
        }

        &-item {
            padding: 0.25rem 0.75rem;

            > a {
                font-weight: 500;
                cursor: pointer;
            }

            > span {
                cursor: pointer;
            }

            & + & {
                margin-top: 0.5rem;
            }

            > span {
                width: 20px;
                height: 20px;
                background: transparent;
                transform: rotate(-90deg);
                cursor: default !important;
            }

            &:hover,
            &.active {
                > a {
                    font-weight: 700;
                }

                > span {
                    background: $primaryLightColor;
                }

                > .sc-megamenu-child {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &-child {
        background: #FFFFFF;
        position: absolute;
        top: calc(0.5rem + 1px);
        width: 75%;
        left: 260px;
        opacity: 0;
        visibility: hidden;
        transition: opacity $mainTransition 0.05s, visibility $mainTransition 0.05s;
        box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
        border-radius: 0px 4px 4px 0;
        width: 856px;

        @include media-breakpoint-up(xxl) {
            left: 306px;
            width: 990px;
        }

        @media (min-width: $break-xxl) {
            left: 376px;
            width: 1200px;
        }

        &-narrow {
            right: auto;
            width: 260px;
            border-radius: 0;
            bottom: 0;

            @include media-breakpoint-up(xxl) {
                width: 306px;
            }

            @media (min-width: $break-xxl) {
                width: 376px;
            }

            .sc-megamenu-child-wrapper {
                padding: 0;
            }

            ul {
                max-height: 100%;
                overflow-y: auto;
            }

            li:not(.sc-menu-list-landings) {
                padding: 0.75rem 0.75rem 0.75rem 1.5rem;

                & + li {
                    border-top: $mainBorder;
                }

                > span:not(.sc-megamenu-children-title) {
                    width: 20px;
                    height: 20px;
                    background: rgba(0, 0, 0, 0);
                    transform: rotate(-90deg);
                    cursor: default !important;
                }

                .sc-megamenu-child {
                    top: 0;
                }

                .sc-menu-list-landings-link:hover {
                    color: $linksColor;
                    text-decoration: underline;
                }

                &:hover {
                    > a:not(.sc-menu-list-landings-link) {
                        font-weight: 700;
                    }

                    > span:not(.sc-megamenu-children-title) {
                        background: $primaryLightColor;
                    }

                    > .sc-megamenu-child {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .sc-megamenu-child-narrow {
                width: 100%;
                left: 100%;
            }
        }

        &-wrapper {
            padding: 2rem;
            overflow-y: auto;
            scroll-behavior: smooth;
            z-index: 1;
            max-height: 700px;

            .sticky-top {
                top: 1.5rem;
            }
        }
    }

    &-children {
        column-count: 3;

        &-item {
            padding-bottom: 1.5rem;
            page-break-inside: avoid;
            break-inside: avoid;

            &-title {
                margin-bottom: 0.75rem;
            }

            li {
                margin-top: 0.25rem;
            }

            a:hover {
                text-decoration: underline;
                color: $linksColor;
            }
        }
    }
}